import React from "react"
import Layout from "../components/layout"
import Seo from "../components/SEO"
// import atomAndHanah from '../content/images/atom-and-hannah/docks.jpg'
import atomAndHanah from '../content/images/atom-and-hannah/HannahandAtomFiances2020-46.jpg'
import styles from '../scss/index.module.scss'
import WeddingDate from "../components/wedding-date"

export default () => {
  return (
    <Layout>
      <Seo />
      <h1>We're Getting Married!</h1>
        <WeddingDate />
        <div className={styles.homePhoto}>
          <img src={atomAndHanah} />
        </div>
        <p>This is cause for celebration!</p>
        <p>We're having a party to celebrate having found each other and to publicly declare our commitment to seeing it through to the very last.</p>
        <p>We're so excited to share it with you!</p>
        <a className="button" href="https://docs.google.com/forms/d/e/1FAIpQLSdzv2CYjQC9FLlxEf5V_hAWH9aTupHey5gt0sPH7lUeNlq66w/viewform?usp=sf_link" target="_blank">
          rsvp now?
        </a>
    </Layout>
  )
}

