import React, { useState } from "react"
import starRed from "../content/images/star-24px-red.svg"
import starYellow from "../content/images/star-24px.svg"
import starBlue from "../content/images/star-24px-blue.svg"
import styles from "../scss/wedding-date.module.scss"
import "../scss/main.scss"

export default props => (
  <div className={styles.weddingDateWrapper}>
    <h2 className={styles.weddingDate} {...props}>
      <img src={starRed} />
      <img src={starYellow} />
      <img src={starBlue} />
      <span className={styles.dateContainer}>
        <span>May 22nd, 2021</span>
        <span className={styles.weddingDateTime}>4:00pm</span>
        <span className={styles.rsvpDate}>Please RSVP by April 9th</span>
      </span>
        <img src={starBlue} />
        <img src={starYellow} />
        <img src={starRed} />
    </h2>
  </div>
)
